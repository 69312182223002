import linkedin from '../assets/img/linkedin.png'
import gmail from '../assets/img/gmail.png'
import github from '../assets/img/github.png'

const SocialButtons = () => {
    return (
        <div className="social-icon mx-auto">
            <a href="https://www.linkedin.com/in/camelia-meraoui/" target="_blank" rel="noopener noreferrer">
                <img src={linkedin} alt="LinkedIn" />
            </a>
            <a href="mailto:camelia.meraoui123@gmail.com">
                <img src={gmail} alt="Gmail" />
            </a>
            <a href="https://github.com/caaam213" target="_blank" rel="noopener noreferrer">
                <img src={github} alt="GitHub" />
            </a>
        </div>
    );
};

export default SocialButtons;