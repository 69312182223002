import {Container, Row, Col} from 'react-bootstrap';
import headerImg from '../assets/img/banner.jpg'

function Banner() {

    return (
        <section className="banner" id="home">
    <Container className='justify-content-center'>
        <Row className="align-items-center pb-5">
        
            <Col xs={12} md={7} xl={8}>
            <span className="tagline">"Believe you can and you're halfway there!"-T.Roosevelt</span>
                <h1>Hi ! I'm MERAOUI Camelia : Junior Computer Engineer</h1>
                <p>Freshly graduated with an engineering degree from France, I am a dynamic and motivated engineer, <span className="special">passionate about data and Artificial Intelligence</span> . Early in my studies,
                     I discovered the potential of data and AI, and I’ve been captivated ever since by their power to <span className="special">make almost anything possible.</span></p>
                <p><span className="special">Adaptable and open to new challenges </span>, I’m driven by my curiosity and my love for learning. 
                    This portfolio highlights projects from both my studies and personal time, reflecting my eagerness to explore new technologies. 
                </p>
                <p><span className="special" id="discover_p">Please, feel free to explore the projects I worked on!</span></p>
            </Col>
            <Col xs={12} md={5} xl={4} className="d-flex justify-content-center align-items-center" style={{ height: '' }}>
                <img src={headerImg} alt="header img" className="img-fluid" />
            </Col>
        </Row>
    </Container>
</section>

    )
}

export default Banner;