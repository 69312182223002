import React from 'react';  
import { Modal, Button } from 'react-bootstrap';

const CreditsModal = ({ show, handleClose }) => {
  return (
    <Modal 
      show={show} 
      onHide={handleClose} 
      centered
      className="dark-theme-modal"
    >
      <Modal.Header className="modal-header">
        <Modal.Title className="modal-title">Images Credits</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <ul>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="https://icons8.com/icon/gwguOe4urWnW/linkedin">
              LinkedIn
            </a> icon from <a target="_blank" rel="noopener noreferrer" href="https://icons8.com">Icons8</a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="https://icons8.com/icon/12599/github">GitHub</a> icon from <a target="_blank" rel="noopener noreferrer" href="https://icons8.com">Icons8</a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="https://icons8.com/icon/ptAjLogGbrSi/gmail-logo">Gmail</a> icon from <a target="_blank" rel="noopener noreferrer" href="https://icons8.com">Icons8</a>
          </li>
          <li>
            Banner background by <a href="https://unsplash.com/fr/@klim11?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Klim Musalimov</a> on <a href="https://unsplash.com/fr/photos/black-textile-on-brown-wooden-table-rDMacl1FDjw?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>
          </li>
          <li>
            API image for Data Integration skill by <a target="_blank" rel="noopener noreferrer" href="https://pixabay.com/fr/users/geralt-9301/?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=6492491">Gerd Altmann </a> 
            from <a target="_blank" rel="noopener noreferrer" href="https://pixabay.com/fr//?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=6492491">Pixabay</a>
          </li>
          <li>Other images are AI generated from <a target="_blank" rel="noopener noreferrer" href="https://www.bing.com/images/create">
              Bing Creator
            </a>
          </li>
        </ul>
      </Modal.Body>
      <Modal.Footer className="modal-footer">
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreditsModal;
