import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import CreditsModal from './CreditsModal';

const Footer = () => {
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <footer style={{ backgroundColor: '#343a40', color: 'white', padding: '20px 0' }}>
      <Container>
        <Row className="align-items-center justify-content-between">
          <Col md={6} sm={12} className="text-md-start text-center">
            <p className="mb-0">&copy; {new Date().getFullYear()} MERAOUI Camelia</p>
          </Col>

          <Col md={6} sm={12} className="text-md-end text-center">
            <Button variant="link" onClick={handleShow} className="image-credits">
              Images Credits
            </Button>
          </Col>
        </Row>
      </Container>

      <CreditsModal show={show} handleClose={handleClose} />
    </footer>
  );
};

export default Footer;
