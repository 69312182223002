import React, { useEffect, useState } from 'react';
import skillsData from '../data/skills.json';
import SliderComponents from './SliderComponents';

const Skills = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    const allCategories = Object.keys(skillsData).map((category) => ({
      name: category,
      images: skillsData[category].map((skill) => ({
        src: require(`../${skill.src}`),
        caption: skill.caption,
      })),
    }));
    setCategories(allCategories);
    if (allCategories.length > 0) setSelectedCategory(allCategories[0].name);
  }, []);

  const handleCategorySelect = (categoryName) => {
    setSelectedCategory(categoryName);
  };

  return (
    <section className="skill" id="skills">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="skill-bx wow zoomIn">
              <h2>Skills</h2>
              <p>
                The Skills section highlights both my technical and personal strengths, developed through my studies, professional experiences, and projects.
                From data engineering and machine learning to mobile and web development, along with adaptability and problem-solving,
                each skill gives me the power to tackle complex challenges as an engineer.
              </p>

              {/* Boutons pour choisir une catégorie */}
              <div className="category-pills d-flex justify-content-center mb-4">
                {categories.map((category) => (
                  <button
                    key={category.name}
                    className={`btn btn-pill mx-2 ${selectedCategory === category.name ? 'selected' : ''}`}
                    onClick={() => handleCategorySelect(category.name)}
                  >
                    {category.name}
                  </button>
                ))}
              </div>

              {/* Affichage du carousel pour la catégorie sélectionnée */}
              {categories
                .filter((category) => category.name === selectedCategory)
                .map((category) => (
                  <div key={category.name} className="skill-category">
                    <h3 className="category-title text-center mt-5">{category.name}</h3>
                    <SliderComponents images={category.images} />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Skills;
