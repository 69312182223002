import './css/Banner.css';
import './css/Navbar.css';
import './css/App.css';
import './css/Skills.css';
import './css/Projects.css';
import './css/Footer.css';
import './css/CreditsModal.css';

import React from 'react';
import NavBar from './components/NavBar'
import Banner from './components/Banner'
import Skills from './components/Skills'
import Projects from './components/Projects'
import Footer from './components/Footer'
import 'bootstrap/dist/css/bootstrap.min.css'

function App() {
  return (
    <div className="App">
      <NavBar />
      <Banner />
      <Skills />
      <Projects />
      <Footer />
    </div>
  );
}

export default App;
