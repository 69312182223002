import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import projectsData from '../data/projects.json';

export const Projects = () => {
  const [show, setShow] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const formattedCategories = Object.keys(projectsData).map((category) => ({
      name: category,
      projects: projectsData[category],
    }));
    setCategories(formattedCategories);
  }, []);

  const handleShow = (project) => {
    setSelectedProject(project);
    setShow(true);
  };

  const handleClose = () => setShow(false);

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col>
            <h2>Projects</h2>
          </Col>
        </Row>
        {categories.map((category) => (
          <div key={category.name} className="project-category">
            <h3>{category.name}</h3>
            <Row className="justify-content-center">
              {category.projects.map((project) => (
                <Col md={4} sm={6} xs={8} key={project.name} className="project-card">
                  <div
                    className="project-rectangle"
                    style={{
                      backgroundImage: `url(${require(`../${project.src}`)})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      borderRadius: '10px',
                      width: '100%',
                      paddingTop: '100%',
                      position: 'relative',
                      color: 'white',
                      fontWeight: 'bold',
                      cursor: 'pointer',
                      transition: 'opacity 0.3s ease',
                    }}
                    onClick={() => handleShow(project)}
                    onMouseEnter={(e) => (e.currentTarget.style.opacity = '0.8')}
                    onMouseLeave={(e) => (e.currentTarget.style.opacity = '1')}
                  >
                    <div
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        textAlign: 'center',
                        padding: '5px 10px',
                        backgroundColor: 'rgba(0, 0, 0, 0.7)',
                        borderRadius: '5px',
                      }}
                    >
                      <span>{project.name}</span>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        ))}
      </Container>

      <Modal size="lg" show={show} onHide={handleClose} centered>
        <Modal.Header closeButton style={{ backgroundColor: '#333', color: 'white' }}>
          <Modal.Title>{selectedProject?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: '#333', color: 'white' }}>
          <p>{selectedProject?.description}</p>
          <p>
            Status of the project: <span className={selectedProject?.status === 'Finished' ? 'finished' : 'not-finished'}>{selectedProject?.status}</span>
          </p>

          <Button className="d-flex justify-content-center mx-auto"  variant="light" href={selectedProject?.url} target="_blank">
            See the code on GitHub
          </Button>
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default Projects;
